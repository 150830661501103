html.js body .off-canvas__toggle{
    border-radius: 45px;
    width: 90px;
    height: 90px;
    top: 15px;
    right: 15px;
    border: 1px solid $color__primary;
    transition: 0.3s all linear;
    span.lines{
        transition: 0.3s all linear;
        &:before,
        &:after{
            transition: 0.3s all linear;
        }
    }
    @include hover-focus-visible{
        background-color: $color__white;
        span.text{
            color: $color__primary;
        }
        span.lines{
            background-color: $color__primary;
            &:before,
            &:after{
                background-color: $color__primary;
            }
        }
    }
    @media(max-width: 992px){
        width: 60px;
        top: 2px;
        height: 60px;
    }
}
html.js body .off-canvas__toggle--close{
    @media(max-width: 992px){
        top:10px
    }
}
html .off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__toggle--open{
    background-color: $color__primary;
    @include hover-focus-visible{
        background-color: $color__white;
        span.text{
            color: $color__primary;
        }
        span.lines{
            // background-color: $color__primary;
            &:before,
            &:after{
                background-color: $color__primary;
            }
        }
    }
}
html.headroom--unpinned.js body .off-canvas__toggle{
    top: 5px;
    @media(max-width: 992px){
        top: 2px;
    }
}