footer{
    background-color: $color__bg-light;
    .newsletter{
        padding: 55px 0;
        background-color: $color__bg-dark;
        .newsletter--wrapper{
            padding:0 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 64px;
            p{
                color: $color__white;
                font-size: 25px;
            }
            a.button{
                background-color: $color__white;
                color: $color__primary;
                border-color: $color__white;
                @include hover-focus-visible{
                    background-color: $color__primary;
                    color: $color__white
                }
            }
        }
    }
    .contact-1,
    .contact-2{
        p{
            >a{
                margin-bottom: 16px;
                display: inline-block;
                &:not(:last-child){
                    margin-bottom: 16px;
                }
                @include hover-focus-visible{
                    color: $color__primary;
                }
            }
        }
       
    }
    .meta-navigation--legal .meta-navigation__button:hover, 
    .meta-navigation--legal .meta-navigation__button:focus{
        color: $color__primary;
    }
}