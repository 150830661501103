body[data-template="home"]{
    .banner.banner--slider:not(.banner--fullscreen) figure picture{
        @media(min-width: 1920px){
            height: 680px;
        }
    }
    .banner{
        .banner__footer{
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
            z-index: 2;
        }
        .splide__pagination button{
            color: $color__white;
            &.is-active{
                color: $color__white!important;
            }
        }
    }
}