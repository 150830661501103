body {
    .dgf-list__button[aria-pressed=true], 
    .dgf-list-combined__button[aria-pressed=true]{
        background-color: $color__primary;
    } 
    .dgf-list__button:not([aria-pressed=true]), 
    .dgf-list-combined__button:not([aria-pressed=true]){
        border-color: $color__primary;
        img{
            filter: $filter__primary;
        }
        @include hover-focus-visible{
            background-color: $color__primary;
        }
    }
}
body {
    .dgf-list.dgf-list--list .card-title a:hover, 
    .dgf-list.dgf-list--list .card-title a:focus{
        color: $color__primary!important;
    }
}