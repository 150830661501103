.section--article-list{
    .article-list__slider {
        .article-list__item{
            a{
                color: $color__font;
                text-decoration: none;
                height: 100%;
                overflow: hidden;
                display: block;
                .article-list__item--wrapper {
                    background-color: $color__white;
                    padding: 48px 32px 32px 32px;
                    height: 100%;
                    time{
                        font-weight: $font__weight--normal;
                        margin-bottom: 16px;
                        display: block;
                    }
                    h3{
                        margin-bottom: 16px;
                        line-height: 1.23;
                    }
                    >p{
                        font-weight: $font__weight--normal;
                        margin-bottom: 5px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                    }
                    .article-list__item--link{
                        display: flex;
                        justify-content: flex-end;
                    }
                }
                @include hover-focus-visible{
                    .article-list__item--wrapper{
                        h3{
                            text-decoration: underline;
                        }
                        .article-list__item--link{
                            img{
                                transform: translateX(5px);
                            }
                        }
                    } 
                }
            }
            
        }
    }
}