.section--intro{
    background-color: $color__bg-light;
    .intro__links{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 16px;
        @media(max-width: 992px){
            margin-top: 32px;
        }
        @media(max-width: 767px){
            grid-template-columns: unset;
            grid-template-rows: unset;
        }
        >a.button{
            background-color: $color__white;
            color: $color__font;
            border: 0;
            display: flex;
            justify-content: space-between;
            padding: 24px 32px;
            border-radius: 50px;
            align-items: center;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            @include transition;
            img{
                filter: $filter__font;
                width: 32px;
                height: 32px;
                @include transition;
            }
            
            &:first-of-type{
                grid-area: 1 / 1 / 2 / 2;
                @media(max-width: 767px){
                    grid-area: unset
                }
            }
            &:last-of-type{
                grid-area: 2 / 2 / 3 / 3;
                @media(max-width: 767px){
                    grid-area: unset
                }
            }
            &:nth-of-type(2){
                grid-area: 1 / 2 / 2 / 3;
                @media(max-width: 767px){
                    grid-area: unset
                }
            }
            &:nth-of-type(3){
                grid-area: 2 / 1 / 3 / 2;
                @media(max-width: 767px){
                    grid-area: unset
                }
            }
            @include hover-focus-visible{
                text-decoration: underline;
                img{
                    transform: translateX(5px);
                }
            }
        }
    }
    .intro__left{
        header{
            h1{
                margin-bottom: 24px;
            }
            margin-bottom: 0!important;
        } 
        p{
            margin-top: 0;
        }
    }
   

}