.section--top-topics{
    .top-topics__item{
        &:not(.top-topics__item--has-image){
            position: relative;
            &:before{
                content: "";
                position: absolute;
                top: 20px;
                right: 0;
                background-image: url('/assets/website/img/signet.svg');
                height: 136px;
                background-size: contain;
                width: 180px;
                @media(max-width: 1250px){
                    position: relative;
                }
                @media(max-width: 992px){
                    display: none;
                }
            }
            @include hover-focus-visible{
                color: $color__primary;
                .top-topics__caption{
                    
                    h3 a{
                        color: $color__primary;
                    }
                    p[aria-hidden=true] img{
                        filter: $filter__primary;
                    }
                    
                }
            }
        }
    }
}